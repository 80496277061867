export const CONSTANTS = {
    CHALLENGE_COUNT: {
        LEVEL_1: 20, // dependency: 11 min!
        LEVEL_2: 30 // dependency: 11 min!
    },
    MAX_RETRIES: {
        LEVEL_1: 1,
        LEVEL_2: 1
    },
    TIMER: {
        LEVEL_1: 30,
        LEVEL_2: 30
    },
    INITIAL_SCORE: {
        LEVEL_1: 1000,
        LEVEL_2: 500
    },
    WIN_POINTS: {
        LEVEL_1: 10,
        LEVEL_2: 15
    },
    SESSION_TIMEOUT: 3600000, // 60 minutes in milliseconds
    WORD_MAX_LENGTH: 8,
    TILE_PENALTY: -3,
    BOARD_SIZE: {
        LEVEL_1: 3,
        LEVEL_2: 4
    }
}

