'use client'

import { useRef, useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

export default function Gallery({ words, media }) {
    const [currentWordIndex, setCurrentWordIndex] = useState(0)
    const [visibleStartIndex, setVisibleStartIndex] = useState(0)
    const [initialOffset, setInitialOffset] = useState(0)
    const containerRef = useRef(null)
    const firstElementRef = useRef(null)

    const VISIBLE_WORDS = media === 'mobile' ? 5 : 11;
    const CENTER_INDEX = media === 'mobile' ? 2 : 5;
    const ITEM_WIDTH = media === 'mobile' ? 71 : 87;

    const calculateOffset = () => {
        if (containerRef.current && firstElementRef.current) {
            const containerRect = containerRef.current.getBoundingClientRect()
            const firstElementRect = firstElementRef.current.getBoundingClientRect()
            const newInitialOffset = firstElementRect.left - containerRect.left + (firstElementRect.width / 2) - 11
            setInitialOffset(newInitialOffset)
        }
    }

    useEffect(() => {
        // Calculate initial offset
        calculateOffset()

        // Add resize event listener
        const handleResize = () => {
            calculateOffset()
        }

        window.addEventListener('resize', handleResize)

        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [media]) // Re-run when media changes

    const getCursorX = () => {
        if (currentWordIndex < CENTER_INDEX) {
            return `${(currentWordIndex * ITEM_WIDTH) + initialOffset}px`
        }
        else if (currentWordIndex >= words.length - (VISIBLE_WORDS - CENTER_INDEX - 1)) {
            return `${((currentWordIndex - visibleStartIndex) * ITEM_WIDTH) + initialOffset}px`
        }
        else {
            return `${(CENTER_INDEX * ITEM_WIDTH) + initialOffset}px`
        }
    }

    useEffect(() => {
        const findNextIncompleteWord = () => {
            const nextIncompleteIndex = words.findIndex((word, index) => {
                const previousWordsCompleted = words
                    .slice(0, index)
                    .every(w => w.completed)
                return previousWordsCompleted && !word.completed
            })

            if (nextIncompleteIndex !== -1) {
                setCurrentWordIndex(nextIncompleteIndex)
                if (nextIncompleteIndex > CENTER_INDEX) {
                    setVisibleStartIndex(Math.min(
                        nextIncompleteIndex - CENTER_INDEX,
                        words.length - VISIBLE_WORDS
                    ))
                }
            }
        }

        findNextIncompleteWord()
    }, [words])

    return (
        <div className="relative w-full mx-auto overflow-hidden pt-4 pb-6">
            <div className="relative flex justify-center" ref={containerRef}>
                <AnimatePresence mode="wait">
                    <motion.div
                        key={visibleStartIndex}
                        className="flex space-x-2 md:space-x-5"
                        initial={{ x: 0 }}
                        animate={{ x: 0 }}
                        exit={{ x: -ITEM_WIDTH }}
                        transition={{
                            type: "spring",
                            damping: 15,
                            stiffness: 150,
                            restDelta: 0.001
                        }}
                    >
                        {words.slice(visibleStartIndex, visibleStartIndex + VISIBLE_WORDS).map((word, index) => (
                            <motion.div
                                key={visibleStartIndex + index}
                                ref={index === 0 ? firstElementRef : null}
                                className="flex flex-col items-center"
                                initial={{
                                    opacity: index === VISIBLE_WORDS - 1 ? 0 : 1,
                                    scale: index === VISIBLE_WORDS - 1 ? 0.8 : 1
                                }}
                                animate={{
                                    opacity: 1,
                                    scale: 1
                                }}
                                exit={{
                                    opacity: index === 0 ? 0 : 1,
                                    scale: index === 0 ? 0.8 : 1
                                }}
                                transition={{
                                    type: "spring",
                                    damping: 15,
                                    stiffness: 300,
                                    restDelta: 0.001
                                }}
                            >
                                <div className="
                                        flex-shrink-0
                                        w-[62.49px] h-[61.90px] md:w-[67.01px] md:h-[66.37px]
                                        rounded-full
                                        relative
                                        bg-white/80
                                        shadow-xl"
                                >
                                    <div className="w-full h-full rounded-full overflow-hidden">
                                        <img
                                            src={word.completed ? word.image : word.cover_image}
                                            alt=""
                                            className="w-full h-full object-cover"
                                        />
                                    </div>
                                    {word.completed && (
                                        <div
                                            className=
                                                {`${word.score > 0 ? 'bg-emerald-500' : 'bg-blue-500'} 
                                                absolute 
                                                w-[22.50px] h-[23.05px] md:w-[24.13px] md:h-[24.71px] 
                                                left-[40.17px] top-[-5px] md:left-[43px] 
                                                rounded-[20.97px] 
                                                justify-center 
                                                items-center 
                                                gap-[8.39px] 
                                                inline-flex`}
                                        >
                                            <span className="
                                                    text-white
                                                    text-[10.98px] md:text-m
                                                    font-semibold font-outfit
                                                    leading-[10.98px] md:leading-[18.44px]
                                                    "
                                            >
                                                {word.score > 0 ? '+' : ''}{word.score}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </motion.div>
                        ))}
                    </motion.div>
                </AnimatePresence>
            </div>
            <motion.div
                className="absolute z-10 bottom-0 left-0 h-5 w-5 flex items-center justify-center"
                initial={{ x: initialOffset }}
                animate={{ x: getCursorX() }}
                transition={{
                    type: "spring",
                    damping: 15,
                    stiffness: 150,
                    restDelta: 0.001
                }}
            >
                <img src="/icons/tooltip.svg" alt="" className="h-4 w-4 md:h-5 md:w-5" />
            </motion.div>
        </div>
    )
}