'use client'

import { GameProvider } from '../context/GameContext'
import GameBoard from '../components/GameBoard'

export default function Home() {
    return (
        <GameProvider>
            <GameBoard />
        </GameProvider>
    )
}

