'use client'

import { motion } from 'framer-motion'
import { useGame } from '../../context/GameContext'
import VioletButton from "../buttons/VioletButton";

export default function Index() {
    const { dispatch } = useGame()

    const handleLevelSelect = (level) => {
        dispatch({ type: 'INITIALIZE_GAME', level })
    }

    const containerVariants = {
        initial: {},
        animate: {
            transition: {
                staggerChildren: 0.2
            }
        }
    }

    const imageVariants = {
        initial: {
            y: -100,
            opacity: 0
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                damping: 10,
                stiffness: 10
            }
        }
    }

    const contentVariants = {
        initial: {
            y: 100,
            opacity: 0
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                type: "spring",
                damping: 20,
                stiffness: 100,
                delayChildren: 0.3,
                staggerChildren: 0.1
            }
        }
    }

    return (
        <div className="bg-[#00474e] fixed inset-0 flex items-center justify-center">
            <motion.div
                className="flex flex-col items-center justify-center gap-4 w-full"
                variants={containerVariants}
                initial="initial"
                animate="animate"
            >
                <motion.div
                    className="w-full flex flex-col items-center justify-center"
                    variants={imageVariants}
                >
                    <img
                        className="h-auto w-auto md:h-[70vh] md:h-[70vh]"
                        src="/splash_screen.png"
                        alt="Vārdu Bagātnieks"
                    />
                </motion.div>

                <motion.div
                    className="flex flex-col items-center gap-4"
                    variants={contentVariants}
                >
                    <motion.div
                        className="md:hidden text-center text-white text-[40px] font-paytone uppercase leading-[48px]"
                    >
                        Vārdu bagātnieks
                    </motion.div>

                    <div className="flex flex-col md:flex-row items-center justify-center gap-3">
                        <VioletButton
                            onClick={() => handleLevelSelect(1)}
                        >
                            1. līmenis
                        </VioletButton>

                        <VioletButton
                            onClick={() => handleLevelSelect(2)}
                        >
                            2. līmenis
                        </VioletButton>
                    </div>
                </motion.div>
            </motion.div>
        </div>
    )
}