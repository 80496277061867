import React from 'react';

const GrayButton = ({ onClick, children, className = '' }) => {
    const baseClasses = "" +
        "h-14 " +
        "px-6 " +
        "w-[320px] " +
        "md:w-auto " +
        "bg-white " +
        "hover:bg-gray-50 " +
        "transition duration-300 " +
        "rounded-full " +
        "border border-gray-300 " +
        "text-gray-600 " +
        "text-lg " +
        "font-semibold " +
        "font-outfit";

    return (
        <button
            className={`${baseClasses} ${className}`}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default GrayButton;