'use client'

import Home from './app/page'

function App() {
  return (
      <div className="min-h-screen">
        <Home />
      </div>
  );
}

export default App;