'use client'

import { createContext, useContext, useReducer, useEffect } from 'react'
import { CONSTANTS } from '../constants/game-constants'
import { wordsRepository } from '../data/WordsRepository'
import { coverImages } from '../data/CoverImagesRepository'

//background image iterator starting point
let currentImageIndex = 1;

const GameContext = createContext()

const initialState = {
    player: {
        score: 0,
        words: [],
        level: null
    },
    currentWord: null,
    timer: {
        seconds: 0,
        isRunning: false
    },
    isGameOver: false,
    session: {
        lastUpdated: Date.now()
    }
}

function gameReducer(state, action) {
    switch (action.type) {
        case 'INITIALIZE_GAME':
            const words = getRandomWords(CONSTANTS.CHALLENGE_COUNT[`LEVEL_${action.level}`])
            return {
                ...state,
                player: {
                    score: CONSTANTS.INITIAL_SCORE[`LEVEL_${action.level}`],
                    words: words,
                    level: action.level
                },
                currentWord: words[0],
                timer: {
                    seconds: CONSTANTS.TIMER[`LEVEL_${action.level}`],
                    isRunning: true
                },
                session: {
                    lastUpdated: Date.now()
                }
            }
        case 'UPDATE_PLAYER_SCORE':
            return {
                ...state,
                player: {
                    ...state.player,
                    score: state.player.score + action.points
                },
                session: {
                    lastUpdated: Date.now()
                }
            }
        case 'UPDATE_WORD_SCORE':
            if (!state.currentWord) return state;

            // Update the current word score
            const updatedCurrentWord = {
                ...state.currentWord,
                score: state.currentWord.score + action.points
            }

            // Update the same word in the words array
            const updatedWordsArray = state.player.words.map(word =>
                word.id === state.currentWord.id ? updatedCurrentWord : word
            )

            return {
                ...state,
                currentWord: updatedCurrentWord,
                player: {
                    ...state.player,
                    words: updatedWordsArray
                },
                session: {
                    lastUpdated: Date.now()
                }
            }
        case 'UPDATE_TIMER':
            return {
                ...state,
                timer: {
                    ...state.timer,
                    seconds: action.seconds
                }
            }
        case 'COMPLETE_WORD':
            const updatedWords = state.player.words.map(word =>
                word.id === state.currentWord.id  // Compare IDs instead
                    ? { ...word, completed: true }
                    : word
            )
            const nextWord = updatedWords.find(w => !w.completed)
            const isGameCompleted = !nextWord;
            return {
                ...state,
                player: {
                    ...state.player,
                    words: updatedWords
                },
                currentWord: nextWord,
                timer: nextWord ? {
                    seconds: CONSTANTS.TIMER[`LEVEL_${state.player.level}`],
                    isRunning: true
                } : {
                    isRunning: false
                },
                isGameOver: isGameCompleted,
                session: {
                    lastUpdated: Date.now()
                }
            }
        case 'RESET_GAME':
            return initialState;
        case 'UPDATE_WORD_RETRIES':
            return {
                ...state,
                player: {
                    ...state.player,
                    words: state.player.words.map(word =>
                        word.id === action.wordId
                            ? { ...word, retries: action.retries }
                            : word
                    )
                },
                currentWord: state.currentWord.id === action.wordId
                    ? { ...state.currentWord, retries: action.retries }
                    : state.currentWord
            };
        default:
            return state
    }
}
//this is to iterate through background images and assign next image to the background of tile
function getNextCoverImage() {
    const imageId = currentImageIndex;
    currentImageIndex = currentImageIndex % Object.keys(coverImages).length + 1;
    return coverImages[imageId].image;
}

function getRandomWords(count) {
    const wordIds = Object.keys(wordsRepository)
    const selectedWords = []
    // Create a copy of wordIds to remove from as we select
    const availableIds = [...wordIds]

    // Make sure we don't try to get more words than exist in repository
    const wordsToGet = Math.min(count, availableIds.length)

    for (let i = 0; i < wordsToGet; i++) {
        // Get random index from remaining available IDs
        const randomIndex = Math.floor(Math.random() * availableIds.length)
        // Get the ID and remove it from available IDs
        const wordId = availableIds.splice(randomIndex, 1)[0]
        const word = wordsRepository[wordId]

        selectedWords.push({
            ...word,
            id: wordId,
            letters: word.name.split(''),
            hint: word.hint,
            position: { x: Math.random(), y: Math.random() },
            scale: 0.8 + Math.random() * 0.4,
            tiles: 9,
            cover_image: getNextCoverImage(),
            score: CONSTANTS.WIN_POINTS[`LEVEL_1`],
            completed: false,
            retries: CONSTANTS.MAX_RETRIES.LEVEL_1
        })
    }
    return selectedWords
}

export function GameProvider({ children }) {
    const [state, dispatch] = useReducer(gameReducer, initialState)

    // Session management
    useEffect(() => {
        const checkSession = () => {
            const now = Date.now()
            if (now - state.session.lastUpdated > CONSTANTS.SESSION_TIMEOUT) {
                // Reset game on session timeout
                dispatch({ type: 'RESET_GAME' })
            }
        }

        const interval = setInterval(checkSession, 600) // Check every minute

        return () => clearInterval(interval)
    }, [state.session.lastUpdated])

    // Handle page visibility change
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                // Save current state to sessionStorage
                sessionStorage.setItem('gameState', JSON.stringify(state))
            } else {
                // Restore state from sessionStorage
                const savedState = sessionStorage.getItem('gameState')
                if (savedState) {
                    const parsedState = JSON.parse(savedState)
                    // Check if session is still valid
                    if (Date.now() - parsedState.session.lastUpdated <= CONSTANTS.SESSION_TIMEOUT) {
                        Object.entries(parsedState).forEach(([key, value]) => {
                            dispatch({ type: `RESTORE_${key.toUpperCase()}`, payload: value })
                        })
                    } else {
                        dispatch({ type: 'RESET_GAME' })
                    }
                }
            }
        }

        document.addEventListener('visibilitychange', handleVisibilityChange)
        return () => document.removeEventListener('visibilitychange', handleVisibilityChange)
    }, [])

    return (
        <GameContext.Provider value={{ state, dispatch }}>
            {children}
        </GameContext.Provider>
    )
}

export const useGame = () => useContext(GameContext)

