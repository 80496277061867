'use client'

import { useEffect, useRef } from 'react'
import { useGame } from '../context/GameContext'
import { cn } from '../lib/utils'

export default function Timer({ onTimeout, isPaused }) {
    const { state, dispatch } = useGame()
    const { seconds, isRunning } = state.timer
    const hasTimedOut = useRef(false)

    useEffect(() => {
        let interval
        // Only run timer if not paused, is running, and has seconds left
        if (isRunning && seconds > 0 && !isPaused) {
            interval = setInterval(() => {
                dispatch({ type: 'UPDATE_TIMER', seconds: seconds - 1 })
            }, 1000)
            hasTimedOut.current = false
        } else if (seconds === 0 && !hasTimedOut.current) {
            dispatch({ type: 'GAME_OVER' })
            onTimeout(); // Call the callback when time runs out
            hasTimedOut.current = true
        }

        return () => clearInterval(interval)
    }, [seconds, isRunning, onTimeout, dispatch, isPaused])

    return (
        <div
            className={cn(
                "w-[100px] h-[60px] md:w-[200px] md:h-[200px] p-4 shadow rounded-lg md:rounded-2xl text-center",
                seconds <= 20 && "animate-pulse bg-gradient-to-b from-[#ffd757] to-[#f57c00]",
                seconds <= 13 && "animate-pulse bg-gradient-to-b from-[#ec5569] to-[#eb2227]",
                seconds > 20 && "bg-gradient-to-b from-[#0c8894] to-[#065c63]"
            )}
        >
            <div className="flex flex-row md:flex-col items-center justify-center space-x-2 md:gap-2 mt-[-1px] md:mt-5">

                <div className="md:order-last items-start justify-start min-w-[3ch] gap-2 flex">
                    <span
                        className="
                            text-right
                            text-2xl md:text-[40px]
                            font-medium font-['Outfit']
                            leading-snug md:leading-[42.38px]
                            tracking-tight"
                    >{seconds}</span>
                    <span
                        className="
                            hidden md:block
                            text-center text-white text-2xl font-normal font-outfit leading-[42.38px]"
                    >sek</span>
                </div>

                <img src="/icons/clock.svg" alt="Clock"
                     className="w-[30.14px] h-[30.14px] md:w-[82px] md:h-[82px] md:order-first"/>

            </div>
        </div>
    )
}

