import React from 'react';

const AboutButton = ({ setModalType, setShowModal, className = "" }) => {
    const isMobile = !className.includes('md:');

    const handleClick = () => {
        setModalType('about');
        setShowModal(true);
    };

    if (isMobile) {
        return (
            <button
                onClick={handleClick}
            >
                <img src="/icons/info-circle.svg" alt="About"/>
            </button>
        );
    }

    return (
        <button
            className="flex flex-col items-center justify-center w-[84px] h-[60px] md:w-[200px] md:h-[200px] text-2xl font-normal font-['Outfit'] p-4 rounded-2xl bg-gradient-to-b from-[#0c8894] to-[#065c63] shadow gap-3"
            onClick={handleClick}
        >
            <div
                className="w-[72px] h-[72px] bg-[#00d881] rounded-[45px] shadow-xl justify-center items-center gap-[9px] inline-flex">
                <div className="w-[40.50px] h-[40.50px] mt-[1px]">
                <img src="/icons/alert-circle.svg" alt=""/>
                </div>
            </div>
            Par spēli
        </button>
    );
};

export default AboutButton;
