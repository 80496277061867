import React from 'react';
import { motion } from 'framer-motion';
import GrayButton from "../buttons/GrayButton";
import VioletButton from "../buttons/VioletButton";

const WordCheckModal = ({ isOpen, word, onCheck, onShowAnswer, onTryAgain, onContinue, stage, isCorrect }) => {
    if (!isOpen) return null;

    const modalVariants = {
        initial: { opacity: 0, y: 50 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: 50 }
    };

    return (
        <motion.div
            className="fixed bottom-0 left-0 right-0 bg-white p-[25px] shadow-lg z-[5]"
            initial="initial"
            animate="animate"
            exit="exit"
            variants={modalVariants}
        >
            <div className="max-w-6xl mx-auto">
                <div className="flex flex-col items-center gap-6">
                    {stage === 'initial' && (
                        <VioletButton onClick={() => onCheck(isCorrect)}>
                            Pārbaudīt
                        </VioletButton>

                    )}

                    {stage === 'checking' && (
                        <div className="flex flex-col gap-4 w-full">
                            <div className="flex flex-col md:flex-row gap-4 w-full items-center justify-center">
                                {isCorrect ? (
                                    <VioletButton onClick={onContinue}>
                                        Turpināt
                                    </VioletButton>
                                ) : (
                                    <>
                                        <GrayButton onClick={onShowAnswer}>
                                            Pareizā atbilde
                                        </GrayButton>
                                        {word.retries > 0 && (
                                            <VioletButton onClick={onTryAgain}>
                                                Mēģini vēlreiz
                                            </VioletButton>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    {stage === 'answer' && (
                        <div className="flex flex-col items-center gap-4 w-full">
                            <VioletButton onClick={onContinue}>
                                Turpināt
                            </VioletButton>
                        </div>
                    )}
                </div>
            </div>
        </motion.div>
    );
};

export default WordCheckModal;