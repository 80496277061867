import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

export default function LetterBlocks({
                                         word,
                                         level,
                                         onComplete,
                                         focusedIndex,
                                         onFocusChange,
                                         onWordFull  // New prop
                                     }) {
    const [letters, setLetters] = useState([]);
    const blocksRef = useRef([]);

    useEffect(() => {
        if (word && word.letters) {
            const initialLetters = word.letters.map((letter, index) => ({
                value: level === 1 && index === 0 ? letter : '',
                isCorrect: level === 1 && index === 0
            }));
            setLetters(initialLetters);
        } else {
            setLetters([]);
        }
    }, [word, level]);

    useEffect(() => {
        if (blocksRef.current[focusedIndex]) {
            blocksRef.current[focusedIndex].focus();
        }
    }, [focusedIndex]);

    const handleInput = (value, index) => {
        if (!word || !word.letters) return;
        if (!/^[A-Za-zĀāČčĒēĢģĪīĶķĻļŅņŠšŪūŽž]$/.test(value)) return;

        const newLetters = [...letters];
        newLetters[index] = {
            value: value.toUpperCase(),
            isCorrect: value.toUpperCase() === word.letters[index].toUpperCase()
        };
        setLetters(newLetters);

        // Check if all letters are filled
        if (newLetters.every(l => l.value)) {
            onWordFull(newLetters);
            return;
        }

        // Check if word is complete and correct
        if (newLetters.every(l => l.isCorrect)) {
            onComplete();
            return;
        }

        // Move focus to next empty block
        const nextIndex = letters.findIndex((l, i) => i > index && !l.value);
        if (nextIndex !== -1) {
            onFocusChange(nextIndex);
        }
    };

    const handleKeyDown = (e, index) => {
        e.preventDefault();

        if (/^[A-Za-zĀāČčĒēĢģĪīĶķĻļŅņŠšŪūŽž]$/.test(e.key)) {
            handleInput(e.key, index);
        }
    };

    return (
        <div className="flex justify-center space-x-1 md:space-x-3">
            {letters.map((letter, index) => (
                <motion.input
                    key={index}
                    ref={el => blocksRef.current[index] = el}
                    type="text"
                    maxLength={1}
                    value={letter.value}
                    className={`
                        w-10 h-10 md:h-[53px] md:w-[53px] xl:h-[71px] xl:w-[71px]
                        text-center 
                        text-[26.18px] md:text-[34.69px] xl:text-[46.47px]
                        leading-[63.67px] 
                        tracking-[-0.93px] 
                        uppercase 
                        rounded-[2.91px] md:rounded 
                        font-bold font-outfit 
                        shadow-[-4px_4px_4px_0px_rgba(0,0,0,0.25)]
                        ${letter.value ? 'bg-gradient-to-b from-[#0c8894] to-[#065c63] text-white' : 'bg-white text-black'}
                    `}
                    style={{ textShadow: '2px 4px 2px rgba(0, 0, 0, 0.25)' }}
                    onKeyDown={e => handleKeyDown(e, index)}
                    onChange={e => handleInput(e.target.value, index)}
                    onClick={() => onFocusChange(index)}
                    readOnly={level === 1 && index === 0}
                />
            ))}
        </div>
    );
}