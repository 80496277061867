import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

export default function ScoreDisplay({ score }) {
    const [prevScore, setPrevScore] = useState(score);
    const [flash, setFlash] = useState(null);

    useEffect(() => {
        if (score !== prevScore) {
            setFlash(score > prevScore ? 'green' : 'blue');
            setTimeout(() => setFlash(null), 300);
            setPrevScore(score);
        }
    }, [score, prevScore]);

    const getGradientColors = () => {
        if (flash === 'green') {
            return {
                from: '#34d399', // emerald-500
                to: '#00c475',
                shadow: '0px 4px 20px rgba(52, 211, 153, 0.5)' // green shadow
            };
        } else if (flash === 'blue') {
            return {
                from: '#3b82f6', // blue-500
                to: '#0088ff',
                shadow: '0px 4px 20px rgba(59, 130, 246, 0.5)' // blue shadow
            };
        }
        return {
            from: '#0c8894',
            to: '#065c63',
            shadow: '01px 2px 2px rgba(0, 0, 0, 0.1)' // default shadow
        };
    };

    const { from, to, shadow } = getGradientColors();

    return (
        <motion.div
            className="
                w-[84px] h-[60px] md:w-[200px] md:h-[200px]
                p-2 md:p-4
                rounded-lg md:rounded-2xl
                flex flex-row md:flex-col
                gap-1 md:gap-3
                "
            initial={{scale: 1}}
            animate={{
                scale: [1, 1.1, 1],
                background: `linear-gradient(to bottom, ${from}, ${to})`,
                boxShadow: shadow
            }}
            transition={{
                scale: {duration: 0.3},
                background: {duration: 0.3},
                boxShadow: {duration: 0.3}
            }}
        >
            <span className="
                    flex
                    text-right justify-center items-center text-white text-base md:text-[40px] font-medium font-outfit
                    min-w-[3ch]
                    md:order-last"

            >{score}
            </span>
            <div className="flex items-center justify-center md:space-y-2 md:mt-4 md:order-first">
                <motion.img
                    src="/icons/diamond.svg"
                    alt="Diamond"
                    className={"w-[25.80px] h-[25.80px] md:w-[78px] md:h-[78px]"}
                    animate={{
                        scale: flash === 'green'
                            ? [1, 1.3, 1]
                            : flash === 'blue'
                                ? [1, 0.7, 1]
                                : 1
                    }}
                    transition={{duration: 0.3}}
                />
            </div>
        </motion.div>
    );
}