import React, { useCallback, useEffect } from 'react';

const VioletButton = ({ onClick, children, className = '' }) => {
    const baseClasses = "" +
        "h-14 " +
        "px-6 " +
        "w-[320px] " +
        "md:w-auto " +
        "text-white " +
        "text-lg " +
        "font-semibold " +
        "font-['Outfit'] " +
        "bg-violet-600 " +
        "hover:bg-violet-700 " +
        "transition " +
        "duration-300 " +
        "rounded-full " +
        "border " +
        "border-violet-600";

    const handleKeyPress = useCallback((event) => {
        if (event.key === 'Enter' && onClick) {
            onClick(event);
        }
    }, [onClick]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    return (
        <button
            className={`${baseClasses} ${className}`}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default VioletButton;