export const AboutRepository = {
    main: {
        heading: "Spēle Burtu bagātnieks",
        text: "Lorem ipsum dolor sit amet consectetur. Suspendisse diam non commodo tincidunt. Urna luctus odio risus adipiscing integer. Tincidunt hac ornare etiam vestibulum non. Ultrices purus egestas in eleifend platea. Volutpat et netus maecenas.\n" +
            "Quis egestas ut augue aliquam et. Enim in neque neque sit ut. Euismod blandit enim sit a dolor diam fusce dolor nam. Vitae et id duis morbi vel habitasse ultricies auctor placerat. Viverra nunc lorem iaculis viverra congue dignissim a tortor. Eu lorem pellentesque tempus cras dui.\n" +
            "Non viverra mi suspendisse sit. Nisl consequat gravida amet venenatis. At tristique egestas ornare nunc iaculis massa. Nulla volutpat lectus blandit tristique. Imperdiet vel lobortis diam phasellus aliquet at. Cursus integer sit sed iaculis faucibus. Vel eget nec sit in maecenas. Dolor laoreet enim sit netus tempus quis tortor mauris nulla. Suspendisse vel nibh id blandit sagittis interdum enim. Vitae semper elit in egestas amet at.\n" +
            "Vel consequat gravida at sed ut odio faucibus morbi. Augue orci lectus neque habitant ullamcorper adipiscing malesuada adipiscing diam. Et mattis volutpat consequat amet sed odio. Sit nulla faucibus dui arcu malesuada consectetur pulvinar eleifend. Posuere duis elementum eget in orci vitae neque diam. Sapien adipiscing a mi arcu quis rhoncus. Eleifend donec sem at interdum proin nisl neque tellus malesuada. Elit iaculis id ut fringilla ipsum ac scelerisque dis molestie. Leo tellus viverra neque vestibulum fames. Lorem ipsum dolor sit amet consectetur. Suspendisse diam non commodo tincidunt. Urna luctus odio risus adipiscing integer. Tincidunt hac ornare etiam vestibulum non. Ultrices purus egestas in eleifend platea. Volutpat et netus maecenas.\n" +
            "Quis egestas ut augue aliquam et. Enim in neque neque sit ut. Euismod blandit enim sit a dolor diam fusce dolor nam. Vitae et id duis morbi vel habitasse ultricies auctor placerat. Viverra nunc lorem iaculis viverra congue dignissim a tortor. Eu lorem pellentesque tempus cras dui.\n" +
            "Non viverra mi suspendisse sit. Nisl consequat gravida amet venenatis. At tristique egestas ornare nunc iaculis massa. Nulla volutpat lectus blandit tristique. Imperdiet vel lobortis diam phasellus aliquet at. Cursus integer sit sed iaculis faucibus. Vel eget nec sit in maecenas. Dolor laoreet enim sit netus tempus quis tortor mauris nulla. Suspendisse vel nibh id blandit sagittis interdum enim. Vitae semper elit in egestas amet at.\n" +
            "Vel consequat gravida at sed ut odio faucibus morbi. Augue orci lectus neque habitant ullamcorper adipiscing malesuada adipiscing diam. Et mattis volutpat consequat amet sed odio. Sit nulla faucibus dui arcu malesuada consectetur pulvinar eleifend. Posuere duis elementum eget in orci vitae neque diam.\n" +
            "Vel consequat gravida at sed ut odio faucibus morbi. Augue orci lectus neque habitant ullamcorper adipiscing malesuada adipiscing diam. Et mattis volutpat consequat amet sed odio. Sit nulla faucibus dui arcu malesuada consectetur pulvinar eleifend. Posuere duis elementum eget in orci vitae neque diam. Sapien adipiscing a mi arcu quis rhoncus. Eleifend donec sem at interdum proin nisl neque tellus malesuada. Elit iaculis id ut fringilla ipsum ac scelerisque dis molestie. Leo tellus viverra neque vestibulum fames. Lorem ipsum dolor sit amet consectetur. Suspendisse diam non commodo tincidunt. Urna luctus odio risus adipiscing integer. Tincidunt hac ornare etiam vestibulum non. Ultrices purus egestas in eleifend platea. Volutpat et netus maecenas.\n" +
            "Quis egestas ut augue aliquam et. Enim in neque neque sit ut. Euismod blandit enim sit a dolor diam fusce dolor nam. Vitae et id duis morbi vel habitasse ultricies auctor placerat. Viverra nunc lorem iaculis viverra congue dignissim a tortor. Eu lorem pellentesque tempus cras dui.\n" +
            "Non viverra mi suspendisse sit. Nisl consequat gravida amet venenatis. At tristique egestas ornare nunc iaculis massa. Nulla volutpat lectus blandit tristique. Imperdiet vel lobortis diam phasellus aliquet at. Cursus integer sit sed iaculis faucibus. Vel eget nec sit in maecenas. Dolor laoreet enim sit netus tempus quis tortor mauris nulla. Suspendisse vel nibh id blandit sagittis interdum enim. Vitae semper elit in egestas amet at.\n" +
            "Vel consequat gravida at sed ut odio faucibus morbi. Augue orci lectus neque habitant ullamcorper adipiscing malesuada adipiscing diam. Et mattis volutpat consequat amet sed odio. Sit nulla faucibus dui arcu malesuada consectetur pulvinar eleifend. Posuere duis elementum eget in orci vitae neque diam.",
    },
    privacy: {
        heading: "Privātuma politika",
        text: "Lorem ipsum dolor sit amet consectetur. Suspendisse diam non commodo tincidunt. Urna luctus odio risus adipiscing integer. Tincidunt hac ornare etiam vestibulum non. Ultrices purus egestas in eleifend platea. Volutpat et netus maecenas.\n" +
            "Quis egestas ut augue aliquam et. Enim in neque neque sit ut. Euismod blandit enim sit a dolor diam fusce dolor nam. Vitae et id duis morbi vel habitasse ultricies auctor placerat. Viverra nunc lorem iaculis viverra congue dignissim a tortor. Eu lorem pellentesque tempus cras dui.",
    },
    contacts: {
        heading: "Kontakti",
        text: "Ja ir jautājumi, lūdzam sazināties ar mums: tālrunis: 67201680; e-pasts: ",
        email: "agentura@valoda.lv",
        text2: "; adrese: Lāčplēša iela 35‒5, Rīga, LV-1011",
    }
}