import React, { useMemo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { CONSTANTS } from '../constants/game-constants';

const getRandomPosition = () => {
    const positions = [
        'translate(-60%, -60%)',
        'translate(-40%, -40%)',
        'translate(-50%, -50%)',
        'translate(-40%, -60%)',
        'translate(-60%, -40%)'
    ];
    return positions[Math.floor(Math.random() * positions.length)];
};

const getRandomSize = () => {
    const variation = (Math.random() * 40 - 20) / 100;
    return 100 * (1 + variation); // Increased base size to 100%
};

export default function TileGrid({
                                     level,
                                     word,
                                     onTileClick,
                                     revealedTiles,
                                     setRevealedTiles,
                                     penalties,
                                     isCompleting = false
                                 }) {
    const size = CONSTANTS.BOARD_SIZE[`LEVEL_${level}`];
    const totalTiles = size * size;

    const imageStyles = useMemo(() => {
        if (level === 2) {
            return {
                width: `${getRandomSize()}%`,
                height: `${getRandomSize()}%`,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: getRandomPosition(),
                display: 'block',
                objectFit: 'contain',
                maxWidth: '150%', // Allow overflow for positioning
                maxHeight: '150%'
            };
        }
        return {
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'block',
            objectFit: 'contain'
        };
    }, [level, word.image]);

    const handleTileClick = (index) => {
        if (revealedTiles.includes(index)) return;
        setRevealedTiles(prev => [...prev, index]);
        onTileClick(penalties[index]);
    };

    return (
        <div
            className="relative overflow-hidden bg-white"
            style={{
                aspectRatio: '1 / 1',
                zIndex: 0
            }}
        >
            {/* Background Image Container */}
            <div
                className="absolute inset-0 flex items-center justify-center overflow-visible"
                style={{ zIndex: 1 }}
            >
                <div className="relative w-full h-full">
                    <img
                        src={word.image}
                        alt=""
                        style={imageStyles}
                    />
                </div>
            </div>

            {/* Grid Layer */}
            <div
                className="relative grid w-full h-full"
                style={{
                    gridTemplateColumns: `repeat(${size}, 1fr)`,
                    zIndex: 2
                }}
            >
                {Array.from({ length: totalTiles }).map((_, index) => (
                    <div key={index} className="relative">
                        <motion.div
                            className="absolute inset-0 cursor-pointer shadow-[-4px_-4px_12px_4px_rgba(0,0,0,0.10)]"
                            style={{
                                display: (revealedTiles.includes(index) || (isCompleting && !revealedTiles.includes(index))) ? 'none' : 'flex',
                                zIndex: 3
                            }}
                            onClick={() => handleTileClick(index)}
                            whileHover={{scale: 1.05}}
                            whileTap={{scale: 0.95}}
                        >
                            <img
                                src={word.cover_image}
                                alt=""
                                className="w-full h-full object-cover"
                            />
                        </motion.div>

                        {(revealedTiles.includes(index) || (isCompleting && !revealedTiles.includes(index))) && (
                            <motion.div
                                className={`
                                    absolute 
                                    inset-0 
                                    flex 
                                    items-center 
                                    justify-center 
                                    text-2xl 
                                    font-bold 
                                    ${penalties[index] === 0 ? 'bg-violet-600 text-white' : 'bg-[#00474e] text-[#00ff98]'}
                                `}
                                initial={{opacity: 1, rotateY: 0}}
                                animate={{
                                    rotateY: 180,
                                    opacity: [1, 1, 1, 0],
                                    y: [0, 0, 0, -200],
                                    rotate: [0, 0, 0, -90],
                                }}
                                transition={{
                                    duration: 1.3,
                                    times: [0, 0.1, 0.6, 1],
                                    ease: "easeInOut",
                                }}
                                style={{
                                    transformStyle: 'preserve-3d',
                                    pointerEvents: 'none',
                                    zIndex: 4
                                }}
                            >
                                <span
                                    style={{transform: 'rotateY(180deg)'}}
                                    className="text-6xl text-shadow-[-4px_-4px_12px_4px_rgba(0,0,0,0.10)]"
                                >
                                    {penalties[index]}
                                </span>
                            </motion.div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}