import React from 'react';

const LevelChangeButton = ({ level, handleLevelChange }) => {
    return (
        <button
            onClick={handleLevelChange}
            className="
                w-auto h-[60px] md:w-[200px] md:h-[200px]
                p-4
                rounded-lg md:rounded-2xl
                bg-gradient-to-b from-[#0c8894] to-[#065c63]
                shadow"
        >
            <div className="flex flex-row md:flex-col items-center space-x-2 mt-0 md:mt-8 gap-1 md:gap-2">
                <span className="
                    pb-7 md:pb-0
                    text-center
                    text-white
                    text-sm md:text-2xl
                    font-normal
                    font-outfit
                    leading-snug md:leading-[42.38px]
                    md:order-second"
                >līmenis</span>
                <div className="w-[60px] md:w-[105px] h-[57.27px] relative md:order-first">
                    <div className="
                            w-14 h-[30.55px] md:w-[105px] md:h-[57.27px]
                            p-0.5 md:p-[4.77px]
                            left-0
                            top-0
                            absolute
                            bg-[#6941c6]
                            rounded-full
                            justify-start
                            items-center
                            inline-flex"
                    >
                        <div className={`
                                w-[25.45px] h-[25.45px] md:w-[47.73px] md:h-[47.73px] 
                                relative 
                                bg-white 
                                rounded-full 
                                shadow 
                                ${level === 2 ? "ml-[26px] md:ml-[47.73px]" : ""}`}
                        />
                    </div>
                    <div className="
                        left-[36px] md:left-[67.09px] top-[2px] md:top-3
                        absolute
                        text-center
                        text-[#a58ddd]
                        text-lg md:text-[32.73px]
                        font-bold font-['Outfit']"
                    >2</div>
                    <div className={`
                        left-[11px] md:left-[21.18px] 
                        top-[2px] md:top-3
                        absolute 
                        text-center 
                        text-lg md:text-[32.73px] 
                        font-bold font-outfit 
                        ${level === 2 ? "text-white" : "text-[#6941c6]"}`}
                    >1</div>
                </div>
            </div>
        </button>
    );
};
export default LevelChangeButton;
